import React, { BaseSyntheticEvent, useState } from 'react'
import { Container, Heading, AppBar, Button } from 'uniqore-components'
import { Link, Menu, MenuItem } from '@material-ui/core'
import { logOut } from 'components/Auth/LogOut'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

const ContactUs = () => {
  const history = useHistory()
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const handleAppBarClick = (e: BaseSyntheticEvent) => {
    setOpenMenu(true)
    setAnchorEl(e.currentTarget)
  }

  return (
    <Container>
      <AppBar
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: () => history.push('/tila') }}
        actions={[{ icon: 'MoreVert', onClick: e => handleAppBarClick(e) }]}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        style={{ marginTop: 20 }}
      >
        <MenuItem
          onClick={() => {
            logOut()
          }}
        >
          Kirjaudu ulos
        </MenuItem>
      </Menu>
      <Heading
        title="Ota yhteyttä"
        body="Voit ottaa yhteyttä soittamalla tai lähettämällä sähköpostia asiakaspalveluumme."
      />
      <Link href="tel:+358 40 683 1215">
        <Button
          label="+358 40 683 1215"
          fullWidth
          color="secondary"
          onClick={() => {}}
          icon="Phone"
        />
      </Link>
      <Link href="mailto:info@stera.com">
        <Button label="info@stera.com" fullWidth color="primary" onClick={() => {}} icon="Email" />
      </Link>
    </Container>
  )
}

export default ContactUs
