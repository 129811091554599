import React, { useState } from 'react'
import {
  Container,
  Heading,
  AppBar,
  Button,
  ListItem,
  SmallPrint,
  ErrorPage
} from 'uniqore-components'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components/macro'
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useLocation } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { sessionIsExpired } from 'features/session'
import { SurveyRecordType, SurveyMetaData } from '../types/survey'
import { CartItem } from '../types/order'
import { Dimensions } from '../types/spaces'
import { getFullDate, getTime } from './Common/DateFunctions'
import { UPDATE_SURVEY_QUERY, GET_ENGAGEMENTS_QUERY } from '../graphql/queries'
import PublicClient from '../api/publicClient'
import defaultError from '../assets/errors/defaultError.svg'

const useStyles = makeStyles(() => ({
  unclickable: {
    '& [class*="makeStyles-showPointer"]': {
      cursor: 'default'
    }
  }
}))

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const OpenedSurvey = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const [showItems, setShowItems] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const Alert = (props: AlertProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }
  const { data, loading, error, refetch } = useQuery(GET_ENGAGEMENTS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let surveys = []
  if (data) {
    surveys = data.serviceEngagements
  }

  const currentSurvey = surveys.find((survey: SurveyRecordType) => survey.id === id)

  const [updateSurvey, { loading: mutationLoading }] = useMutation(UPDATE_SURVEY_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
      }
    },
    // eslint-disable-next-line consistent-return
    onError(mutationError) {
      if (mutationError) {
        if (!localStorage.getItem('token')) {
          dispatch(sessionIsExpired(true))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                image={defaultError}
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  })

  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  let lampsQuantity = 0
  const currentMetadata = currentSurvey?.metadata.find(
    (meta: SurveyMetaData) => meta.key === 'survey'
  )
  const installationDate = currentMetadata?.value?.locationDetails.installationDate
  const widths: number[] = []
  const depths: number[] = []
  const heights: number[] = []

  const currentRecords = currentMetadata?.value.order.cart.products

  currentRecords?.map((record: CartItem) => {
    const lamps = record.quantity
    lampsQuantity += lamps
    record.cartSpace.givenDimensions.map((size: Dimensions) => {
      widths.push(size.width)
      depths.push(size.depth)
      heights.push(size.height)
    })
  })
  let squareMeters = 0

  widths.forEach((width, i: number) => {
    depths.forEach((depth, index: number) => {
      if (index === i) {
        squareMeters += Number(width) * Number(depth)
      }
    })
  })

  const sendOffer = () => {
    const date = new Date()
    if (currentSurvey?.id) {
      updateSurvey({
        variables: {
          id: process.env.REACT_APP_UPDATE_SURVEY_STATUS,
          form: {
            ...currentMetadata.value,
            updatedDateText: `${getFullDate(date)} klo ${getTime(date)}`,
            dateText: currentMetadata.value.dateText,
            email: localStorage.getItem('email'),
            uniqoreID: currentSurvey?.id,
            status: 'Tarjouspyyntö lähetetty'
          }
        }
      })
    }
  }

  if (error) {
    if (!localStorage.getItem('token')) {
      dispatch(sessionIsExpired(true))
    } else {
      const link = {
        url: window.location.pathname,
        text: 'Yritä uudestaan'
      }
      return (
        <div>
          <ErrorPage
            backgroundColor="#fff"
            color="#000"
            image={defaultError}
            link={link}
            text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
          />
        </div>
      )
    }
  }

  return (
    <div>
      <AppBar
        text={previousLocation}
        border
        maxWidth="sm"
        mainAction={{ icon: 'ArrowBack', onClick: () => history.push('/omat-kartoitukset') }}
      />
      <Container>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowSnackbar(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert severity="error">
            Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.
          </Alert>
        </Snackbar>
        {loading ? (
          <Loader>
            <CircularProgress size="36px" color="inherit" style={{ marginTop: 20 }} />
          </Loader>
        ) : (
          <div>
            <Heading
              title="Avattu kartoitus"
              body={currentMetadata.value?.order?.locationDetails?.name}
            />
            <ListItem
              text="Valaistussuunnitelma"
              subtitles={[
                `${currentMetadata.value.order.cart.products.length} tilaa, ${lampsQuantity} valaisinta`,
                `Yhteensä ${squareMeters} m2`
              ]}
              metaIcon={showItems ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
              onClick={() => setShowItems(!showItems)}
            />
            {showItems &&
              currentRecords.map((record: CartItem, i: number) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i}>
                    <Fade in={showItems}>
                      <ListItem
                        text={`${record?.cartSpace?.type?.label} / ${record?.cartSpace?.lightType?.label} (${record?.cartSpace?.lightType?.lux} lx)`}
                        subtitles={[
                          `${record.cartSpace.givenDimensions[0].width} m (X) x ${record.cartSpace.givenDimensions[0].depth} m (Z) x ${record.cartSpace.givenDimensions[0].height} m (Y)`,
                          record.cartSpace.givenDimensions.length > 1
                            ? `${record.cartSpace.givenDimensions[1].width} m (X) x ${record.cartSpace.givenDimensions[1].depth} m (Z) x ${record.cartSpace.givenDimensions[1].height} m (Y)`
                            : '',
                          `${record.product.fields.SKU} (${record.quantity} kpl)`,
                          record?.cartSpace?.installationDetails
                            ? `Asennustapa: ${record?.cartSpace?.installationDetails?.installationType}`
                            : '',
                          record?.cartSpace?.installationDetails
                            ? `Valaisinlinjojen määrä: ${record?.cartSpace?.installationDetails?.railsAmount}`
                            : ''
                        ]}
                        metaIcon=""
                        image={record.cartSpace.space.image}
                        actions={[]}
                        onClick={() => {}}
                      />
                    </Fade>
                  </div>
                )
              })}
            {currentSurvey && (
              <div>
                <ListItem
                  className={classes.unclickable}
                  text="Kohteen tiedot"
                  subtitles={[
                    currentMetadata?.value?.order?.locationDetails?.name
                      ? currentMetadata?.value?.order?.locationDetails?.name
                      : '',
                    `Asennuksen ajankohta: ${new Date(installationDate).toLocaleDateString(
                      'fi-FI'
                    )}`
                  ]}
                  metaIcon=""
                  onClick={() => {}}
                />
                <ListItem
                  className={classes.unclickable}
                  text={
                    currentMetadata.value.offerDetails.promise ||
                    currentMetadata.value.offerDetails.contact ||
                    currentMetadata.value.offerDetails.visit ||
                    currentMetadata.value.offerDetails.dialux
                      ? 'Lisäpalvelut'
                      : ''
                  }
                  subtitles={[
                    currentMetadata.value.offerDetails.promiseDate
                      ? `Tarjous jätettävä ${new Date(
                          currentMetadata.value.offerDetails.promiseDate
                        ).toLocaleDateString('fi-FI')}`
                      : '',
                    currentMetadata.value.offerDetails.contact ? 'Haluan yhteydenoton' : '',
                    currentMetadata.value.offerDetails.visit ? 'Haluan kohdekäynnin' : '',
                    currentMetadata.value.offerDetails.dialux ? 'Haluan Dialux-suunnitelman' : ''
                  ]}
                  metaIcon=""
                  onClick={() => {}}
                />
              </div>
            )}
            <div style={{ paddingBottom: 20 }}>
              {currentMetadata.value.status === 'Tarjouspyyntö lähetetty' ? (
                <div>
                  <SmallPrint text="Tarjouspyyntö lähetetty!" align="center" />
                  <SmallPrint text={currentMetadata.value.updatedDateText} align="center" />
                </div>
              ) : (
                <Button
                  disabled={mutationLoading}
                  label={
                    mutationLoading ? (
                      <Container>
                        <div
                          style={{
                            width: 35,
                            margin: 'auto'
                          }}
                        >
                          <CircularProgress size="24px" color="inherit" />
                        </div>
                      </Container>
                    ) : (
                      'Lähetä tarjouspyyntö'
                    )
                  }
                  color="primary"
                  fullWidth
                  onClick={sendOffer}
                />
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default OpenedSurvey
