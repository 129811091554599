import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Login, Container, TextField, Button, AppBar, Spinner } from 'uniqore-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PublicClient from 'api/publicClient'
import { useMutation } from '@apollo/client'
import { RESET_EMAIL_QUERY, RESET_PASSWORD_QUERY } from '../../graphql/queries'
import { passwordResetDone } from '../../features/password-reset'

const useStyles = makeStyles(() => ({
  form: {
    marginTop: '35%'
  }
}))

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ForgotPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const code = query.get('code')
  const uid = query.get('uid')
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isEmailRequest] = useState<boolean>(code === null)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const [resetEmail, { loading: resetEmailLoading }] = useMutation(RESET_EMAIL_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetCustomerPassword.error) {
        setErrorMessage(mutationResult.resetCustomerPassword.error)
      } else if (mutationResult.resetCustomerPassword.ok) {
        setSuccess('Salasanan vaihtoviesti on lähetetty sähköpostiisi!')
        setEmail('')
        setEmailValid('')
      }
    }
  })

  const [resetPassword] = useMutation(RESET_PASSWORD_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.resetCustomerPassword.error) {
        setErrorMessage(mutationResult.resetCustomerPassword.error)
      } else if (mutationResult.resetCustomerPassword.ok) {
        setPassword('')
        setPasswordRepeat('')
        dispatch(passwordResetDone(true))
        history.push('/kirjaudu')
      }
    }
  })

  const validateEmail = () => {
    const emailFormat = /\S+@\S+\.\S+/
    return emailFormat.test(email)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setErrorMessage('')
    if (isEmailRequest) {
      if (email === '') {
        setErrorMessage('Sähköposti ei voi olla tyhjä')
      } else if (!validateEmail()) {
        setEmailValid('Sähköpostiosoite on virheellinen')
      } else {
        resetEmail({
          variables: {
            email,
            auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
          }
        })
      }
    } else {
      resetPassword({
        variables: {
          auth: process.env.REACT_APP_CUSTOMER_AUTH_ID,
          code,
          uid,
          password,
          passwordRepeat
        }
      })
    }
  }

  return (
    <Container>
      <AppBar
        border
        text="Takaisin"
        mainAction={{ icon: 'ArrowBack', onClick: () => history.push('/kirjaudu') }}
      />
      <form className={classes.form} onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
        {success ? (
          <>
            <Login headline={success} subtitle="" />
          </>
        ) : (
          <>
            {isEmailRequest ? (
              <Login
                headline="Salasana unohtunut?"
                subtitle=""
                contentComponents={[
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder="Sähköposti"
                    prefix={{ icon: 'MailOutline' }}
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    errorText={errorMessage || emailValid}
                  />
                ]}
                actionComponents={[
                  <Button type="submit" color="primary" fullWidth label="NOLLAA SALASANA" />
                ]}
              />
            ) : (
              <>
                <Login
                  headline="Vaihda salasana"
                  subtitle=""
                  contentComponents={[
                    <TextField
                      autoFocus
                      fullWidth
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value)
                      }}
                      placeholder="Salasana"
                      name="password"
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      suffix={{
                        icon: showPassword ? 'Visibility' : 'VisibilityOff',
                        onClick: () => setShowPassword(!showPassword)
                      }}
                      errorText={errorMessage}
                    />,
                    <TextField
                      value={passwordRepeat}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPasswordRepeat(e.target.value)
                      }
                      placeholder="Salasana uudestaan"
                      fullWidth
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      suffix={{
                        icon: showPassword ? 'Visibility' : 'VisibilityOff',
                        onClick: () => setShowPassword(!showPassword)
                      }}
                      errorText={errorMessage}
                    />
                  ]}
                  actionComponents={[
                    <Button type="submit" color="primary" fullWidth label="VAIHDA SALASANA" />
                  ]}
                />
              </>
            )}
          </>
        )}
        {resetEmailLoading ? (
          <Container>
            <div
              style={{
                width: 60,
                margin: '25px auto'
              }}
            >
              <Spinner />
            </div>
          </Container>
        ) : null}
      </form>
    </Container>
  )
}

export default ForgotPassword
