import React, { useState } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { createDraft } from 'features/order'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { AppState } from 'store'
import { Space } from 'types/spaces'
import { Container, Heading, AppBar, ListItem, Dialog, Button } from 'uniqore-components'
import { getAvailableSpaces } from 'util/spaces'

const spaces = getAvailableSpaces()

const LocationShape = () => {
  const history = useHistory()
  const currentDraft = useSelector((state: AppState) => state.order.currentDraft)
  const dispatch = useDispatch()
  const [dialog, setDialog] = useState<boolean>(false)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => history.goBack()

  const onBackDialog = () => {
    if (previousLocation === 'Takaisin etusivulle') {
      setDialog(true)
    } else onBack()
  }

  const onClickSpace = (space: Space, prevLocation: string) => {
    // If we are editing an existing draft, reuse its ID. Otherwise generate new one.
    const id = currentDraft?.id ?? nanoid()
    dispatch(createDraft({ space, id }))
    history.push('/tilan-mitat', { prevLocation })
  }

  return (
    <div>
      <AppBar
        text={previousLocation}
        maxWidth="sm"
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBackDialog }}
      />
      <Container>
        <Dialog
          subtitle="Haluatko varmasti palata takaisin etusivulle ja lopettaa kartoituksen?"
          fullWidth
          open={dialog}
          actionComponents={[
            <Button
              label="Peruuta"
              variant="text"
              color="primary"
              disablePadding
              onClick={() => {
                setDialog(false)
              }}
            />,
            <Button
              label="Kyllä"
              color="primary"
              disablePadding
              onClick={() => history.push('/')}
            />
          ]}
          onClose={() => setDialog(false)}
        />
        <Heading title="Tilan muoto" subtitle="Valitse alta minkä muotoisen tilan haluat lisätä" />
        <>
          {spaces.map(space => (
            <ListItem
              key={space.id}
              image={space.image}
              subtitles={[`${space.zones.length} ${space.zones.length > 1 ? 'aluetta' : 'alue'}`]}
              text={space.label}
              metaIcon="ArrowForward"
              onClick={() => onClickSpace(space, 'Tilan muoto')}
            />
          ))}
        </>
      </Container>
    </div>
  )
}

export default LocationShape
