import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
  Action
} from '@reduxjs/toolkit'
import orderSlice from 'features/order'
import passwordResetSlice from 'features/password-reset'
import sessionExpiredSlice from 'features/session'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
  createTransform
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistConfig } from 'redux-persist/es/types'

const persistConfig: PersistConfig<any> = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['order', 'passwordReset', 'session'],
  transforms: [
    createTransform(JSON.stringify, toRehydrate =>
      JSON.parse(toRehydrate, (key, value) =>
        typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
          ? new Date(value)
          : value
      )
    )
  ]
}

const rootReducer = combineReducers({
  order: orderSlice,
  passwordReset: passwordResetSlice,
  session: sessionExpiredSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})

export const persistor = persistStore(store)

export type AppState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>
export default { store, persistor }
