import React from 'react'
import { setCurrentDraft } from 'features/order'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppState } from 'store'
import { AppBar, Button, Container, Heading, BottomBar } from 'uniqore-components'
import { CartItem } from '../types/order'
import CartListItem from './Common/CartListItem'

const LightingPlans = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cart = useSelector((state: AppState) => state.order.cart)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => {
    history.goBack()
  }

  const onAddNew = (prevLocation: string) => {
    dispatch(setCurrentDraft({ cartSpace: undefined }))
    history.push('/tilan-muoto', { prevLocation })
  }

  const showDetails = (cartItem: CartItem, prevLocation: string) => {
    history.push(`/alustavat-suunnitelmat/${cartItem.id}`, { prevLocation })
  }

  const onSubmit = (prevLocation: string) => {
    history.push('/kohteen-tiedot', { prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading title="Alustavat valaistussuunnitelmat" />
        <div style={{ marginBottom: 120 }}>
          {cart.products.map(cartItem => (
            <CartListItem
              key={cartItem.id}
              cartItem={cartItem}
              actions={[]}
              metaIcon="ArrowForward"
              onClick={() => showDetails(cartItem, 'Alustavat valaistussuunnitelmat')}
            />
          ))}
          <Button
            role="radio"
            icon="Add"
            label="Lisää uusi tila"
            style={{ marginBottom: 120 }}
            onClick={() => onAddNew('Alustavat suunnitelmat')}
          />
        </div>
        {cart.products.length > 0 && (
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                color="primary"
                disablePadding
                type="submit"
                fullWidth
                label="ETEENPÄIN"
                onClick={() => onSubmit('Alustavat valaistussuunnitelmat')}
              />
            ]}
          />
        )}
      </Container>
    </div>
  )
}

export default LightingPlans
