import { Space, SpaceType, LightType } from 'types/spaces'
import space1Thumbnail from 'assets/shapes/i.svg'
import space2Thumbnail from 'assets/shapes/l.svg'
import space3Thumbnail from 'assets/shapes/t.svg'
import space1zone from 'assets/shapes/i-measurements.svg'
import space2zone1 from 'assets/shapes/l-left-measurements.svg'
import space2zone2 from 'assets/shapes/l-right-measurements.svg'
import space3zone1 from 'assets/shapes/t-top-measurements.svg'
import space3zone2 from 'assets/shapes/t-bottom-measurements.svg'

const SPACE_1: Space = {
  id: 'rectangle',
  label: 'Suorakaide',
  image: space1Thumbnail,
  zones: [{ id: 'space1zone1', image: space1zone, order: 1 }]
}

const SPACE_2: Space = {
  id: 'l-shape',
  label: 'L-muoto',
  image: space2Thumbnail,
  zones: [
    { id: 'space2zone1', image: space2zone1, order: 1 },
    { id: 'space2zone2', image: space2zone2, order: 2 }
  ]
}

const SPACE_3: Space = {
  id: 't-shape',
  label: 'T-muoto',
  image: space3Thumbnail,
  zones: [
    { id: 'space3zone1', image: space3zone1, order: 1 },
    { id: 'space3zone2', image: space3zone2, order: 2 }
  ]
}

export const getAvailableSpaces = (): Space[] => {
  return [SPACE_1, SPACE_2, SPACE_3]
}

const WAREHOUSE: SpaceType = {
  id: 'warehouse',
  label: 'Varasto',
  installationTypes: [
    { type: 'Pinta-asennus', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] },
    { type: 'Kiskoasennus', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] },
    { type: 'Ripustusvaijerit', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] }
  ]
}
const OFFICE: SpaceType = {
  id: 'office',
  label: 'Toimistot',
  installationTypes: [
    {
      type: 'Pinta-asennus',
      products: ['Office Linear 25', 'Office Linear 35', 'Office Linear 45', 'Office Linear 55']
    },
    {
      type: 'Kiskoasennus',
      products: ['Office Linear 25', 'Office Linear 35', 'Office Linear 45', 'Office Linear 55']
    },
    {
      type: 'Alaslaskettu katto',
      products: ['Office Panel 25', 'Office Panel 35', 'Office Panel 45', 'Office Panel 55']
    },
    {
      type: 'Ripustusvaijerit',
      products: ['Office Linear 25', 'Office Linear 35', 'Office Linear 45', 'Office Linear 55']
    }
  ]
}
const PRODUCTION: SpaceType = {
  id: 'production',
  label: 'Tuotantotilat',
  installationTypes: [
    { type: 'Pinta-asennus', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] },
    { type: 'Kiskoasennus', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] },
    { type: 'Ripustusvaijerit', products: ['Linear-HE-S-55', 'Linear-HE-M-95', 'Linear-HE-L-145'] }
  ]
}
const OTHER_SPACE: SpaceType = {
  id: 'other',
  label: 'Muu kuiva sisätila',
  installationTypes: [
    {
      type: 'Pinta-asennus',
      products: [
        'Linear-HE-S-55',
        'Linear-HE-M-95',
        'Linear-HE-L-145',
        'Office Linear 25',
        'Office Linear 35',
        'Office Linear 45',
        'Office Linear 55'
      ]
    },
    {
      type: 'Kiskoasennus',
      products: [
        'Linear-HE-S-55',
        'Linear-HE-M-95',
        'Linear-HE-L-145',
        'Office Linear 25',
        'Office Linear 35',
        'Office Linear 45',
        'Office Linear 55'
      ]
    },
    {
      type: 'Alaslaskettu katto',
      products: ['Office Panel 25', 'Office Panel 35', 'Office Panel 45', 'Office Panel 55']
    },
    {
      type: 'Ripustusvaijerit',
      products: [
        'Linear-HE-S-55',
        'Linear-HE-M-95',
        'Linear-HE-L-145',
        'Office Linear 25',
        'Office Linear 35',
        'Office Linear 45',
        'Office Linear 55'
      ]
    }
  ]
}

export const getSpaceTypes = () => {
  return [WAREHOUSE, OFFICE, PRODUCTION, OTHER_SPACE]
}

const LIGHT_LOW: LightType = { id: 'low', label: 'Matala', lux: 300 }
const LIGHT_NORMAL: LightType = { id: 'normal', label: 'Normaali', lux: 500 }
const LIGHT_HIGH: LightType = { id: 'high', label: 'Korkea', lux: 1000 }

export const getLightTypes = () => {
  return [LIGHT_LOW, LIGHT_NORMAL, LIGHT_HIGH]
}

export const getSuggestedLightType = (spaceType: SpaceType): LightType | undefined => {
  switch (spaceType) {
    case WAREHOUSE:
      return LIGHT_LOW
    case OFFICE:
      return LIGHT_NORMAL
    case PRODUCTION:
      return LIGHT_HIGH
    case OTHER_SPACE:
      return LIGHT_LOW
    default:
      return undefined
  }
}
