import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { AppState } from 'store'
import { CartItem } from 'types/order'
import { AppBar, Container, Heading, ListItem } from 'uniqore-components'
import { getProductSuggestions } from 'util/calculator'
import styled from 'styled-components/macro'

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Suggestions = () => {
  const history = useHistory()
  const cartSpace = useSelector((state: AppState) => state.order.currentDraft)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'
  const onBack = () => history.goBack()
  const [suggestions, setSuggestions] = useState<CartItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const updateSuggestions = async () => {
      if (cartSpace) {
        setLoading(true)
        const suggestedProducts = await getProductSuggestions(cartSpace)
        setSuggestions(suggestedProducts)
        setLoading(false)
      }
    }
    updateSuggestions()
  }, [])

  const onClick = (cartItem: CartItem, prevLocation: string) => {
    history.push('/tuote', { cartItem, prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading
          title="Alustava valaistussuunnitelma"
          subtitle="Alta löydät sopivimmat tuotteet tilan perustietojen pohjalta. Valitse soveltuva malli."
        />
        {loading && (
          <Loader>
            <CircularProgress color="inherit" />
          </Loader>
        )}
        {suggestions
          .sort(
            (a, b) =>
              a.product.fields.Yksikköhinta * a.quantity -
              b.product.fields.Yksikköhinta * b.quantity
          )
          .map((cartItem, index) => {
            const { product, quantity } = cartItem
            return (
              <div key={product.id}>
                <Heading title={`Vaihtoehto ${index + 1}:`} align="left" />
                <ListItem
                  text={product.fields.SKU}
                  subtitles={[
                    `${product.fields['Teho (W)']} W, ${product.fields['Valovirta (lm)']} lm`,
                    `${quantity} kpl`
                  ]}
                  metaIcon="ArrowForward"
                  image={product.fields.Kuva[0]?.url}
                  imageRectangle
                  onClick={() => onClick(cartItem, 'Alustava valaistussuunnitelma')}
                />
              </div>
            )
          })}
      </Container>
    </div>
  )
}
export default Suggestions
