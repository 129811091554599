import React from 'react'
import { Container, AppBar, Heading, ListItem, Button } from 'uniqore-components'
import { useHistory } from 'react-router'

const PreOffer = () => {
  const history = useHistory()
  const [value, setValue] = React.useState('')

  const handleClick = (v: string) => {
    setValue(v)
  }
  return (
    <Container>
      <AppBar text="Alustava tarjous" border centerText />
      <Heading
        title="Arvio #12345"
        body="Alta löydät alustavan tarjouksemme syöttämästäsi kohteesta. Kohde on tallennettu myös omiin kohteisiisi."
      />
      <ListItem
        text="Steralux Linear 40"
        subtitles={['4 x 123,45€/kpl']}
        avatar={{
          image:
            'https://www.steralux.com/client/steralux/userfiles/steralux-linear-retail-web-8001744375246.jpg'
        }}
        metaIcon="ArrowForward"
        onClick={() => {}}
        hightlightMeta
      />
      <ListItem
        text="Steralux Linear 70"
        subtitles={['2 x 234,50€/kpl']}
        avatar={{
          image:
            'https://www.steralux.com/client/steralux/userfiles/steralux-linear-retail-web-8001744375246.jpg'
        }}
        metaIcon="ArrowForward"
        onClick={() => {}}
        hightlightMeta
      />
      <Heading title="Yhteensä" body="1234,50€ + alv. 24%" />
      <Button
        checked={value === 'Pyydä tarjous'}
        role="radio"
        label="Pyydä tarjous"
        fullWidth
        onClick={() => {
          handleClick('Pyydä tarjous')
          history.push('/tarjous-lähetetty')
        }}
      />
      <Button
        checked={value === 'Siirry omille sivuille'}
        role="radio"
        label="Siirry omille sivuille"
        fullWidth
        onClick={() => {
          handleClick('Siirry omille sivuille')
        }}
      />
    </Container>
  )
}

export default PreOffer
