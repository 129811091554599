import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  sessionIsExpired: false,
  acceptedCookies: false
}

const sessionExpiredSlice = createSlice({
  name: 'sessionExpired',
  initialState,
  reducers: {
    sessionIsExpired(state, { payload }: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.sessionIsExpired = payload
    },
    acceptedCookies(state, { payload }: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.acceptedCookies = payload
    }
  }
})

export const { sessionIsExpired, acceptedCookies } = sessionExpiredSlice.actions
export default sessionExpiredSlice.reducer
