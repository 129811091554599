import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { AppState } from 'store'
import { LightType } from 'types/spaces'
import { AppBar, Button, Container, Heading, TextField, BottomBar } from 'uniqore-components'
import { selectLightType } from 'features/order'
import { getLightTypes } from 'util/spaces'
import { Fade } from '@material-ui/core'

const NeedOfLight = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const activeType = useSelector((state: AppState) => state.order.currentDraft?.lightType)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => history.goBack()

  const lightTypes = getLightTypes()

  const selectType = (lightType: LightType) => {
    dispatch(selectLightType({ lightType }))
  }

  const onButtonClick = (prevLocation: string) => {
    if (activeType) history.push('/asennustapa', { prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading
          title="Valotarve"
          subtitle="Valitse tilan valontarve työn tarkkuuden ja valon tarpeen mukaan."
        />
        <div style={{ marginBottom: 100 }}>
          {lightTypes.map(lightType => (
            <Button
              key={lightType.id}
              checked={lightType.id === activeType?.id}
              role="radio"
              metaText={`${lightType.lux} lx`}
              label={lightType.label}
              onClick={() => selectType(lightType)}
            />
          ))}
          <Button
            label="Muu, mikä?"
            metaText="Syötä määrä (lx)"
            checked={activeType?.id === 'custom'}
            role="radio"
            onClick={() => selectType({ id: 'custom', label: '', lux: 0 })}
          />
          <Fade in={activeType?.id === 'custom'}>
            <TextField
              style={{ float: 'right' }}
              type="number"
              label="Syötä määrä"
              onChange={e =>
                selectType({ id: 'custom', label: '', lux: Number.parseInt(e.target.value, 10) })
              }
              suffix={{ text: 'lux' }}
            />
          </Fade>
        </div>
        <div style={{ width: 200, height: 25 }} />
        <BottomBar
          style={{
            position: 'absolute',
            bottom: 30,
            width: '100%'
          }}
          maxWidth="sm"
          components={[
            <Button
              color="primary"
              disablePadding
              type="submit"
              fullWidth
              label="ETEENPÄIN"
              onClick={() => onButtonClick('Valotarve')}
              disabled={!activeType}
            />
          ]}
        />
      </Container>
    </div>
  )
}

export default NeedOfLight
