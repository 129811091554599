import React, { useState } from 'react'
import {
  Container,
  Heading,
  AppBar,
  Switch,
  Button,
  DatePicker,
  BottomBar
} from 'uniqore-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppState } from 'store'
import { useHistory } from 'react-router'
import Fade from '@material-ui/core/Fade'
import { selectOffer } from 'features/order'
import { OfferDetails } from 'types/order'

const OfferDetailsPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const onBack = () => history.goBack()
  const currentOffer = useSelector((state: AppState) => state.order.offerDetails)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const [checkedPromise, setCheckedPromise] = useState<boolean>(currentOffer?.promise ?? false)
  const [checkedContact, setCheckedContact] = useState<boolean>(currentOffer?.contact ?? false)
  const [checkedVisit, setCheckedVisit] = useState<boolean>(currentOffer?.visit ?? false)
  const [checkedDialux, setCheckedDialux] = useState<boolean>(currentOffer?.dialux ?? false)
  const [promiseDate, setDate] = useState<Date>(currentOffer?.promiseDate ?? new Date())
  const changeDate = (date: Date) => {
    setDate(date)
  }

  const offerDetails: OfferDetails = {
    promise: checkedPromise,
    promiseDate: checkedPromise ? promiseDate : undefined,
    contact: checkedContact,
    visit: checkedVisit,
    dialux: checkedDialux
  }

  const submitHandler = (e: React.FormEvent, prevLocation: string) => {
    e.preventDefault()
    dispatch(selectOffer({ offerDetails }))
    history.push('/yhteenveto', { prevLocation })
  }
  return (
    <div>
      <AppBar
        text={previousLocation}
        maxWidth="sm"
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container maxWidth="sm">
        <Heading
          title="Lisäpalvelut"
          body="Saat alustavan arvion välittömästi tarjouksenjätön jälkeen. Tarjouksen lisäksi voit valita alla olevia maksuttomia lisäpalveluita."
        />
        <form style={{ paddingBottom: 40 }}>
          <Switch
            label="Tarjous luvattu loppuasiakkaalle"
            color="primary"
            subtitles={['Valitse päivä']}
            checked={checkedPromise}
            onChange={() => setCheckedPromise(!checkedPromise)}
          />
          {checkedPromise && (
            <Fade in={checkedPromise}>
              <DatePicker date={promiseDate} variant="inline" onChange={changeDate} disablePast />
            </Fade>
          )}
          <div style={{ marginBottom: 30 }}>
            <Switch
              label="Haluan yhteydenoton"
              color="primary"
              subtitles={['Arkisin 24 tunnin sisään']}
              checked={checkedContact}
              onChange={() => setCheckedContact(!checkedContact)}
            />
            <Switch
              label="Haluan kohdekäynnin"
              color="primary"
              subtitles={['1-2 arkipäivän sisään']}
              checked={checkedVisit}
              onChange={() => setCheckedVisit(!checkedVisit)}
            />
            <Switch
              label="Haluan Dialux-suunnitelman"
              color="primary"
              subtitles={['Valaistussuunnitelmat 2-5 arkipäivän sisään riippuen kohteesta']}
              checked={checkedDialux}
              onChange={() => setCheckedDialux(!checkedDialux)}
            />
          </div>
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                color="primary"
                disablePadding
                type="submit"
                fullWidth
                label="ETEENPÄIN"
                onClick={(e: React.FormEvent) => submitHandler(e, 'Lisäpalvelut')}
              />
            ]}
          />
        </form>
      </Container>
    </div>
  )
}

export default OfferDetailsPage
