import { addZoneDimensions } from 'features/order'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppState } from 'store'
import { Dimensions, Zone } from 'types/spaces'
import { useLocation } from 'react-router-dom'
import { AppBar, Button, Container, Heading, TextField, BottomBar } from 'uniqore-components'
import { Center } from './Common/Utilities'

type Dimension = 'width' | 'depth' | 'height'
// eslint-disable-next-line no-unused-vars
type DimensionsState = { [key in Dimension]: string }

const initialDimensionState: DimensionsState = {
  width: '',
  depth: '',
  height: ''
}

// eslint-disable-next-line no-unused-vars
type ErrorsState = { [key in Dimension]: string }

const initialErrorState: ErrorsState = {
  width: '',
  depth: '',
  height: ''
}

const stateToRedux = (zone: Zone, dimensions: DimensionsState): Dimensions => {
  const result: Dimensions = {
    zone,
    width: Number.parseFloat(dimensions.width || '') || 0,
    depth: Number.parseFloat(dimensions.depth || '') || 0,
    height: Number.parseFloat(dimensions.height || '') || 0
  }
  return result
}

const fields: Array<{ id: Dimension; label: string }> = [
  { id: 'width', label: 'Leveys (X)' },
  { id: 'depth', label: 'Syvyys (Z)' },
  { id: 'height', label: 'Asennuskorkeus (Y)' }
]

const LocationDimensions = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentDraft = useSelector((state: AppState) => state.order.currentDraft)
  const { state } = useLocation<{ prevLocation: string; activeZoneIndex: number }>()
  const [activeZoneIndex, setActiveZoneIndex] = useState<number>(
    state?.activeZoneIndex > 0 ? state.activeZoneIndex : 0
  )

  if (!currentDraft) {
    /* Return empty div so that type checking works properly below.
    history.push does not break execution, so we return here to ensure 
    currentDraft exists when rendering the main content. */
    history.push('/tilan-muoto')
    return <></>
  }

  const { space, givenDimensions } = currentDraft
  const { label, zones, id } = space

  const givenHeight =
    givenDimensions.length > 0 && givenDimensions[0].zone.order === 1
      ? givenDimensions[0]?.height
      : givenDimensions[1]?.height
  const activeZone = zones[activeZoneIndex]

  const amountOfZones = zones.length

  const previousLocation =
    activeZoneIndex > 0
      ? `Tilan mitat ${activeZoneIndex}/${amountOfZones}`
      : state?.prevLocation || 'Tilan muoto'

  const [dimensions, setDimensions] = useState<DimensionsState>(initialDimensionState)
  const [dimensionErrors, setDimensionErrors] = useState<ErrorsState>(initialErrorState)

  const ShowPreviousMeasurement = () => {
    if (activeZoneIndex === 1) {
      givenDimensions.map(dimension => {
        if (dimension.zone.id.includes('zone2') && givenHeight) {
          setDimensions({
            height: givenHeight.toString(),
            width: dimension.width.toString(),
            depth: dimension.depth.toString()
          })
        } else {
          setDimensions({
            ...dimensions,
            height: givenHeight.toString()
          })
        }
      })
    } else {
      givenDimensions.map(dimension => {
        if (dimension.zone.id.includes('zone1')) {
          setDimensions({
            width: dimension.width.toString(),
            height: dimension.height.toString(),
            depth: dimension.depth.toString()
          })
        }
      })
    }
  }

  useEffect(() => {
    ShowPreviousMeasurement()
  }, [givenHeight, givenDimensions, activeZoneIndex])

  const onFieldChange = (name: Dimension, value: string) => {
    setDimensions({
      ...dimensions,
      [name]: value
    })

    let error = ''
    if (Number.isNaN(Number.parseFloat(value)) || Number.parseFloat(value) === 0) {
      error = 'Kentän on oltava numeroarvo ja arvon on oltava suurempi kuin 0.'
    }
    setDimensionErrors({ ...dimensionErrors, [name]: error })
  }

  const emptyFieldExists = () => {
    const fieldValues = Object.values(dimensions)
    const emptyExists = fieldValues.some(dimension => dimension === '')
    return emptyExists
  }

  const activeErrorsExist = () => {
    const errors = Object.values(dimensionErrors)
    const errorExists = errors.some(error => {
      return !!error
    })
    return errorExists
  }

  const validateFields = () => {
    if (emptyFieldExists()) return false
    if (activeErrorsExist()) return false
    return true
  }

  const checkRequiredFields = () => {
    const errorsFields: { [key: string]: string } = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(dimensions)) {
      if (value === '') {
        errorsFields[key] = 'Tämä kenttä on pakollinen'
      }
    }
    setDimensionErrors({
      ...dimensionErrors,
      width: errorsFields.width,
      depth: errorsFields.depth,
      height: errorsFields.height
    })
  }

  const onBack = (prevLocation: string) => {
    if (activeZoneIndex === 0) {
      setDimensions(initialDimensionState)
      history.push('/tilan-muoto', { prevLocation })
    } else {
      setActiveZoneIndex(activeZoneIndex - 1)
    }
  }

  const onButtonClick = (prevLocation: string) => {
    checkRequiredFields()
    if (!validateFields()) return
    const newDimensions = stateToRedux(activeZone, dimensions)
    dispatch(addZoneDimensions({ dimensions: newDimensions }))
    if (zones.length > activeZoneIndex + 1) {
      setActiveZoneIndex(activeZoneIndex + 1)
      if (givenDimensions.length < 2) {
        setDimensions(initialDimensionState)
      } else {
        givenDimensions.map(dimension => {
          if (dimension.zone.id.includes('zone2') && givenHeight) {
            setDimensions({
              height: givenHeight.toString(),
              width: dimension.width.toString(),
              depth: dimension.depth.toString()
            })
          } else {
            setDimensions({
              ...dimensions,
              height: givenHeight.toString()
            })
          }
        })
      }
    } else {
      history.push('/kohteen-tyyppi', { prevLocation, activeZoneIndex })
    }
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: () => onBack('Takaisin etusivulle') }}
      />
      <Container>
        <Heading title="Tilan mitat" subtitle={`Alue ${activeZoneIndex + 1}: ${label}`} />
        <Center>
          <img src={activeZone.image} alt="Alueen kuva" />
        </Center>
        <div style={{ marginBottom: 120 }}>
          {fields.map(field => (
            <TextField
              disabled={
                field.id === 'height' &&
                activeZoneIndex + 1 > 1 &&
                (id === 'l-shape' || id === 't-shape')
              }
              key={field.id}
              errorText={
                field.id === 'height' && activeZoneIndex + 1 > 1 ? '' : dimensionErrors[field.id]
              }
              fullWidth
              label={field.label}
              suffix={{ text: 'metriä' }}
              value={
                field.id === 'height' && activeZoneIndex + 1 > 1
                  ? givenHeight.toString()
                  : dimensions[field.id]
              }
              onChange={e => onFieldChange(field.id, e.target.value)}
              required
            />
          ))}
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              color="primary"
              disablePadding
              type="submit"
              fullWidth
              label="ETEENPÄIN"
              onClick={() =>
                onButtonClick(
                  activeZoneIndex > 0
                    ? `Tilan mitat ${activeZoneIndex + 1}/${amountOfZones}`
                    : 'Tilan mitat'
                )
              }
            />
          ]}
        />
      </Container>
    </div>
  )
}

export default LocationDimensions
