import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { SpaceType } from 'types/spaces'
import { AppBar, Button, Container, Heading } from 'uniqore-components'
import { getSpaceTypes, getSuggestedLightType } from 'util/spaces'
import { selectLightType, selectType } from 'features/order'

const LocationType = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation<{ prevLocation: string; activeZoneIndex: number }>()
  const activeZoneIndex = state?.activeZoneIndex
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => {
    if (activeZoneIndex > 0) {
      history.push('/tilan-mitat', { activeZoneIndex })
    } else history.goBack()
  }
  const types = getSpaceTypes()

  const onButtonClick = (prevLocation: string, type: SpaceType) => {
    dispatch(selectType({ type }))
    const lightSuggestion = getSuggestedLightType(type)
    if (lightSuggestion) dispatch(selectLightType({ lightType: lightSuggestion }))
    history.push('/valotarve', { prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading
          title="Kohteen tyyppi"
          subtitle="Pääset tarkentamaan valotarpeen (lx) seuraavalla sivulla."
        />
        <>
          {types.map(type => (
            <Button
              key={type.id}
              label={type.label}
              role="radio"
              onClick={() => {
                onButtonClick('Kohteen tyyppi', type)
              }}
            />
          ))}
        </>
      </Container>
    </div>
  )
}

export default LocationType
