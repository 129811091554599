import { createTheme, ThemeOptions } from '@material-ui/core/styles'
import { defaultTheme } from 'uniqore-components'

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#E5B313',
      contrastText: '#fff'
    },
    secondary: {
      main: '#394247'
    }
  },
  typography: {
    ...defaultTheme.typography,
    h6: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '31px'
    }
  }
} as ThemeOptions)

export default theme
