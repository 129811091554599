import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Container, Button, ErrorPage, Onboarding } from 'uniqore-components'
import splash from 'assets/svg/splash.svg'
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { sessionIsExpired } from 'features/session'
import format from 'date-fns/format'
import { AppState } from 'store'
import { useMutation, useQuery } from '@apollo/client'
import PublicClient from '../api/publicClient'
import { getFullDate, getTime } from './Common/DateFunctions'
import { UPDATE_SURVEY_QUERY, GET_UNIQORE_ID_QUERY } from '../graphql/queries'
import defaultError from '../assets/errors/defaultError.svg'

const Splash = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const currentState = useSelector((state: AppState) => state)
  const locationDetails = useSelector((state: AppState) => state.order.locationDetails)
  const offerDetails = useSelector((state: AppState) => state.order.offerDetails)
  const { data, error, startPolling, stopPolling } = useQuery(GET_UNIQORE_ID_QUERY, {
    client: PublicClient,
    variables: {
      id: localStorage.getItem('resultId')
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(result) {
      if (result.formResult.state === 'SUCCESS') {
        stopPolling()
      } else {
        startPolling(3000)
      }
    }
  })

  const uniqoreID = data?.formResult?.result?.engagement_id

  const Alert = (props: AlertProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }
  const [updateSurvey, { loading: mutationLoading }] = useMutation(UPDATE_SURVEY_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        localStorage.setItem('uniqoreID', uniqoreID)
        history.push('/tarjous-lähetetty')
      }
    },
    // eslint-disable-next-line consistent-return
    onError(mutationError) {
      if (mutationError) {
        if (!localStorage.getItem('token')) {
          dispatch(sessionIsExpired(true))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                image={defaultError}
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  })

  const sendOffer = () => {
    const date = new Date()
    if (uniqoreID) {
      updateSurvey({
        variables: {
          id: process.env.REACT_APP_UPDATE_SURVEY_STATUS,
          form: {
            ...currentState,
            offerDetails: {
              ...offerDetails,
              promiseDate:
                offerDetails?.promiseDate && format(offerDetails.promiseDate, 'yyyy-MM-dd'),
              promiseDateLocal:
                offerDetails?.promiseDate && format(offerDetails.promiseDate, 'dd.MM.yyyy')
            },
            locationDetails: {
              ...locationDetails,
              installationDate:
                locationDetails?.installationDate &&
                format(locationDetails?.installationDate, 'yyyy-MM-dd'),
              installationDateLocal:
                locationDetails?.installationDate &&
                format(locationDetails?.installationDate, 'dd.MM.yyyy')
            },
            email: localStorage.getItem('email'),
            currentUser: {
              firstName: localStorage.getItem('firstName'),
              lastName: localStorage.getItem('lastName'),
              phone: localStorage.getItem('phone'),
              city: localStorage.getItem('city'),
              address: localStorage.getItem('address'),
              zip: localStorage.getItem('zip'),
              company: localStorage.getItem('companyName')
            },
            updatedDateText: `${getFullDate(date)} klo ${getTime(date)}`,
            dateText: localStorage.getItem('createdTime'),
            uniqoreID,
            status: 'Tarjouspyyntö lähetetty'
          }
        }
      })
    }
  }

  if (error) {
    if (!localStorage.getItem('token')) {
      dispatch(sessionIsExpired(true))
    } else {
      const link = {
        url: window.location.pathname,
        text: 'Yritä uudestaan'
      }
      return (
        <div>
          <ErrorPage
            backgroundColor="#fff"
            color="#000"
            image={defaultError}
            link={link}
            text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
          />
        </div>
      )
    }
  }
  return (
    <Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert severity="error">Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.</Alert>
      </Snackbar>
      <Onboarding
        actionDirection="column"
        screens={[
          {
            title: 'Kartoitus tallennettu',
            subtitle:
              'Kartoituksesi on nyt tallennettu. Voit pyytää suoraan kartoituksestasi tarjouspyynnön tai voit siirtyä omille sivuille ja tehdä tarjouspyynnön myöhemmin.',
            image: splash
          }
        ]}
        actionComponents={[
          <Button
            role="radio"
            disabled={mutationLoading}
            label={
              mutationLoading || !uniqoreID ? (
                <Container>
                  <div
                    style={{
                      width: 35,
                      margin: 'auto'
                    }}
                  >
                    <CircularProgress size="24px" color="secondary" style={{ marginTop: 10 }} />
                  </div>
                </Container>
              ) : (
                'Lähetä tarjouspyyntö'
              )
            }
            fullWidth
            onClick={sendOffer}
          />,
          <Button
            role="radio"
            disabled={mutationLoading}
            label="Siirry omille sivuille"
            fullWidth
            onClick={() => {
              localStorage.setItem('uniqoreID', uniqoreID)
              history.push('/tila')
            }}
          />
        ]}
        showIndicators={false}
      />
    </Container>
  )
}

export default Splash
