import React, { BaseSyntheticEvent, useState } from 'react'
import { Container, Heading, AppBar, Button, ListItem, ErrorPage } from 'uniqore-components'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'
import { CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { setCurrentDraft, clearCart, clearLocationDetails, clearOfferDetails } from 'features/order'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sessionIsExpired } from 'features/session'
import { useQuery } from '@apollo/client'
import { logOut } from 'components/Auth/LogOut'
import { SurveyRecordType, SurveyMetaData } from '../types/survey'
import { GET_ENGAGEMENTS_QUERY } from '../graphql/queries'
import PublicClient from '../api/publicClient'
import defaultError from '../assets/errors/defaultError.svg'

const Loader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const MySurveys = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = useState(false)
  // const [anchorEl, setAnchorEl] = useState(null)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const {
    data: engagementsData,
    loading,
    error
  } = useQuery(GET_ENGAGEMENTS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let records = []
  if (engagementsData) {
    records = engagementsData.serviceEngagements
  }

  const showDetails = (id: string, prevLocation: string) => {
    history.push(`/avattu-kartoitus/${id}`, { prevLocation })
  }

  const addSurvey = (prevLocation: string) => {
    dispatch(setCurrentDraft({ cartSpace: undefined }))
    dispatch(clearCart())
    dispatch(clearLocationDetails())
    dispatch(clearOfferDetails())
    history.push('/tilan-muoto', { prevLocation })
  }

  if (error) {
    if (!localStorage.getItem('token')) {
      dispatch(sessionIsExpired(true))
    } else {
      const link = {
        url: window.location.pathname,
        text: 'Yritä uudestaan'
      }
      return (
        <div>
          <ErrorPage
            backgroundColor="#fff"
            color="#000"
            image={defaultError}
            link={link}
            text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
          />
        </div>
      )
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleAppBarClick = (e: BaseSyntheticEvent) => {
    setOpenMenu(true)
    setAnchorEl(e.currentTarget)
  }

  return (
    <div>
      <AppBar
        text={previousLocation}
        border
        maxWidth="sm"
        mainAction={{ icon: 'ArrowBack', onClick: () => history.push('/tila') }}
        actions={[{ icon: 'MoreVert', onClick: e => handleAppBarClick(e) }]}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        style={{ marginTop: 20 }}
      >
        <MenuItem
          onClick={() => {
            logOut()
          }}
        >
          Kirjaudu ulos
        </MenuItem>
      </Menu>
      <Container>
        <Heading title="Omat kartoitukset" />
        {loading ? (
          <Loader>
            <CircularProgress
              size="36px"
              color="inherit"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Loader>
        ) : (
          <div>
            {records.map((record: SurveyRecordType) => {
              const currentMetadata = record?.metadata.find(
                (meta: SurveyMetaData) => meta.key === 'survey'
              )
              return (
                <ListItem
                  key={record.id}
                  text={currentMetadata?.value?.order?.locationDetails?.name}
                  subtitles={[
                    `Tila: ${currentMetadata?.value.status}`,
                    `Luotu: ${currentMetadata?.value.dateText}`
                  ]}
                  metaIcon="ArrowForward"
                  onClick={() => showDetails(record.id, 'Omat kartoitukset')}
                  hightlightMeta
                />
              )
            })}
            <Button
              label="Tee uusi kartoitus"
              color="secondary"
              icon="Add"
              fullWidth
              onClick={() => addSurvey('Omat kartoitukset')}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default MySurveys
