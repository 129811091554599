export const getFullDate = (date: Date) => {
  const yy = String(date.getFullYear())
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const today = `${dd}.${mm}.${yy}`
  return today
}

export const getTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const time = `${hours}:${minutes}`
  return time
}
