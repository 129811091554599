import { createSlice } from '@reduxjs/toolkit'
import { OrderState, LocationDetails, OfferDetails, CartItem, CartSpace } from 'types/order'
import { Dimensions, LightType, Space, SpaceType } from 'types/spaces'

interface CreateDraftAction {
  payload: {
    id: string
    space: Space
  }
}
interface AddZoneDimensionsAction {
  payload: {
    dimensions: Dimensions
  }
}
interface SelectTypeAction {
  payload: {
    type: SpaceType
  }
}
interface SelectLightTypeAction {
  payload: {
    lightType: LightType
  }
}

interface SelectInstallationTypeAction {
  payload: {
    installationDetails: {
      installationType: string
      installationComment?: string | undefined
      railsAmount?: number
    }
  }
}

interface SelectLocationDetailsAction {
  payload: {
    locationDetails: LocationDetails
  }
}

interface SelectOfferDetailsActions {
  payload: {
    offerDetails: OfferDetails
  }
}

interface AddItemToCartAction {
  payload: {
    cartItem: CartItem
  }
}

interface RemoveItemFromCart {
  payload: {
    cartItem: CartItem
  }
}

interface SetCurrentDraftAction {
  payload: {
    cartSpace: CartSpace | undefined
  }
}

const initialState: OrderState = {
  cart: {
    products: []
  }
}

/* eslint-disable no-param-reassign */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    createDraft(state, { payload }: CreateDraftAction) {
      const { id, space } = payload
      state.currentDraft = { id, space, givenDimensions: [] }
    },
    addZoneDimensions(state, { payload }: AddZoneDimensionsAction) {
      const { currentDraft } = state
      if (!currentDraft) return

      const { dimensions } = payload
      const currentDimensions = currentDraft.givenDimensions.filter(currentDimension => {
        return currentDimension.zone.id !== dimensions.zone.id
      })

      currentDraft.givenDimensions = currentDimensions.concat(dimensions)
    },
    selectType(state, { payload }: SelectTypeAction) {
      const { currentDraft } = state
      if (!currentDraft) return

      const { type } = payload
      currentDraft.type = type
    },
    selectLightType(state, { payload }: SelectLightTypeAction) {
      const { currentDraft } = state
      if (!currentDraft) return

      const { lightType } = payload
      currentDraft.lightType = lightType
    },
    selectInstallationDetails(state, { payload }: SelectInstallationTypeAction) {
      const { currentDraft } = state
      if (!currentDraft) return

      const { installationDetails } = payload
      currentDraft.installationDetails = installationDetails
    },
    selectLocation(state, { payload }: SelectLocationDetailsAction) {
      const { locationDetails } = payload
      state.locationDetails = locationDetails
    },
    selectOffer(state, { payload }: SelectOfferDetailsActions) {
      const { offerDetails } = payload
      state.offerDetails = offerDetails
    },
    addItemToCart(state, { payload }: AddItemToCartAction) {
      const { cartItem } = payload
      const products = state.cart.products.filter(item => item.id !== cartItem.id)
      products.push(cartItem)
      state.cart.products = products
    },
    removeItemFromCart(state, { payload }: RemoveItemFromCart) {
      const { cartItem } = payload
      state.cart.products = state.cart.products.filter(item => item.id !== cartItem.id)
    },
    clearCart(state) {
      state.cart.products = []
    },
    setCurrentDraft(state, { payload }: SetCurrentDraftAction) {
      state.currentDraft = payload.cartSpace
    },
    clearLocationDetails(state) {
      state.locationDetails = {
        name: '',
        address: '',
        postCode: 0,
        postAddress: '',
        installationDate: new Date(),
        additional: ''
      }
    },
    clearOfferDetails(state) {
      state.offerDetails = {}
    }
  }
})

export const {
  createDraft,
  addZoneDimensions,
  selectType,
  selectLightType,
  selectInstallationDetails,
  selectLocation,
  selectOffer,
  addItemToCart,
  removeItemFromCart,
  clearCart,
  setCurrentDraft,
  clearLocationDetails,
  clearOfferDetails
} = orderSlice.actions
export default orderSlice.reducer
