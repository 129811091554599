import { removeItemFromCart, setCurrentDraft } from 'features/order'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { AppState } from 'store'
import { useLocation } from 'react-router-dom'
import { AppBar, Button, Container, Dialog, ListItem, BottomBar, Heading } from 'uniqore-components'

const LightingPlanDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const cartItems = useSelector((state: AppState) => state.order.cart.products)
  const cartItem = cartItems.find(p => p.id === id)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => history.goBack()

  const onDelete = () => {
    if (cartItem) dispatch(removeItemFromCart({ cartItem }))
    history.goBack()
  }

  const onEdit = (targetUrl: string, prevLocation: string) => {
    // Activate the selected item as the draft before proceeding to edit page
    dispatch(setCurrentDraft({ cartSpace: cartItem?.cartSpace }))
    history.push(targetUrl, { prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading
          title="Tilan määritykset"
          body="Voit muokata tilan määrityksiä alta tai palata takaisin alustavaan valaistussuunnitelmaan."
        />
        {cartItem && (
          <>
            <ListItem
              text={cartItem.cartSpace.space.label}
              image={cartItem.cartSpace.space.image}
              subtitles={cartItem.cartSpace.givenDimensions.map(
                d => `${d.width} m (X) x ${d.depth} m (Z), ${d.height} m (Y)`
              )}
              metaIcon="Edit"
              onClick={() => onEdit('/tilan-muoto', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <ListItem
              text={cartItem.cartSpace.type?.label}
              subtitles={['Kohteen tyyppi']}
              metaIcon="Edit"
              onClick={() => onEdit('/kohteen-tyyppi', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <ListItem
              text={`${cartItem.cartSpace.lightType?.lux} lx`}
              subtitles={['Valontarve']}
              metaIcon="Edit"
              onClick={() => onEdit('/valotarve', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <ListItem
              text={`${cartItem.cartSpace.installationDetails?.installationType}`}
              subtitles={['Asennustapa']}
              metaIcon="Edit"
              onClick={() => onEdit('/asennustapa', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <ListItem
              text={`Valaisinlinjojen määrä: ${cartItem.cartSpace.installationDetails?.railsAmount} kpl`}
              subtitles={['Valaisinlinjat']}
              metaIcon="Edit"
              onClick={() => onEdit('/asennustapa', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <ListItem
              text={cartItem.product.fields.SKU}
              metaIcon="Edit"
              subtitles={[
                `${cartItem.product.fields['Teho (W)']} W, ${cartItem.product.fields['Valovirta (lm)']} lm`,
                `${cartItem.quantity} kpl`
              ]}
              image={cartItem.product.fields.Kuva[0]?.url}
              onClick={() => onEdit('/ehdotukset', 'Alustava suunnitelma')}
              hightlightMeta
            />
            <Button
              icon="Delete"
              style={{ marginBottom: 120 }}
              label="Poista tämä tila"
              role="radio"
              onClick={() => setDialogOpen(true)}
            />
            <BottomBar
              style={{ position: 'absolute', bottom: 30, width: '100%' }}
              maxWidth="sm"
              components={[
                <Button
                  color="primary"
                  disablePadding
                  type="submit"
                  fullWidth
                  label="TAKAISIN"
                  onClick={onBack}
                />
              ]}
            />
          </>
        )}
        <Dialog
          title="Haluatko varmasti poistaa tämän tilan?"
          maxWidth="xs"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          actionComponents={[
            <Button label="PERUUTA" variant="default" onClick={() => setDialogOpen(false)} />,
            <Button label="POISTA" onClick={onDelete} color="primary" />
          ]}
        />
      </Container>
    </div>
  )
}

export default LightingPlanDetail
