import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'store'
import { sessionIsExpired } from 'features/session'
import FirstTimeOnboarding from 'components/FirstTimeOnboarding'
import LocationDimensions from 'components/LocationDimensions'
import LocationShape from 'components/LocationShape'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Whitelabel } from 'uniqore-components'
import LocationType from 'components/LocationType'
import NeedOfLight from 'components/NeedOfLight'
import InstallationType from 'components/InstallationType'
import LocationDetailsPage from 'components/LocationDetails'
import OfferDetailsPage from 'components/OfferDetails'
import LocationSummary from 'components/LocationSummary'
import PreOffer from 'components/PreOffer'
import OfferSent from 'components/OfferSent'
import Status from 'components/Status'
import ContactUs from 'components/ContactUs'
import MySurveys from 'components/MySurveys'
import OpenedSurvey from 'components/OpenedSurvey'
import Splash from 'components/Splash'
import Suggestions from 'components/Suggestions'
import SignIn from 'components/Auth/SignIn'
import ForgotPassword from 'components/Auth/ForgotPassword'
import Product from 'components/Product'
import LightingPlans from 'components/LightingPlans'
import LightingPlanDetail from 'components/LightingPlanDetail'
import PrivateRoute from 'components/Auth/PrivateRoute'
import LoginDialog from 'components/Auth/LoginDialog'

function App() {
  const dispatch = useDispatch()
  if (!localStorage.getItem('token')) {
    dispatch(sessionIsExpired(true))
  }
  const { sessionIsExpired: session } = useSelector((state: AppState) => state.session)
  return (
    <>
      <div style={{ marginBottom: 50 }}>
        <Router>
          <Switch>
            <Route exact path="/kirjaudu" component={SignIn} />
            <Route exact path="/palauta-salasana" component={ForgotPassword} />
            {session && <LoginDialog />}
            <PrivateRoute exact path="/" component={FirstTimeOnboarding} />
            <PrivateRoute exact path="/tilan-muoto" component={LocationShape} />
            <PrivateRoute exact path="/tilan-mitat" component={LocationDimensions} />
            <PrivateRoute exact path="/kohteen-tyyppi" component={LocationType} />
            <PrivateRoute exact path="/valotarve" component={NeedOfLight} />
            <PrivateRoute exact path="/asennustapa" component={InstallationType} />
            <PrivateRoute exact path="/ehdotukset" component={Suggestions} />
            <PrivateRoute exact path="/tuote" component={Product} />
            <PrivateRoute exact path="/alustavat-suunnitelmat" component={LightingPlans} />
            <PrivateRoute exact path="/alustavat-suunnitelmat/:id" component={LightingPlanDetail} />
            <PrivateRoute exact path="/kohteen-tiedot" component={LocationDetailsPage} />
            <PrivateRoute exact path="/tarjous-tiedot" component={OfferDetailsPage} />
            <PrivateRoute exact path="/yhteenveto" component={LocationSummary} />
            <PrivateRoute exact path="/alustava-tarjous" component={PreOffer} />
            <PrivateRoute exact path="/tarjous-lähetetty" component={OfferSent} />
            <PrivateRoute exact path="/tila" component={Status} />
            <PrivateRoute exact path="/ota-yhteyttä" component={ContactUs} />
            <PrivateRoute exact path="/omat-kartoitukset" component={MySurveys} />
            <PrivateRoute exact path="/avattu-kartoitus/:id" component={OpenedSurvey} />
            <PrivateRoute exact path="/kartoitus-tallennettu" component={Splash} />
          </Switch>
        </Router>
      </div>
      <Whitelabel style={{ position: 'absolute', bottom: 0, width: '100%' }} />
    </>
  )
}

export default App
