import React from 'react'
import { Heading, Button, Container, BottomBar } from 'uniqore-components'
import { useHistory } from 'react-router'
import { OfferDetails } from 'types/order'
import { selectOffer } from 'features/order'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { ReactComponent as OfferSentSVG } from 'assets/svg/offerSent.svg'

const OfferSent = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const offer = useSelector((state: AppState) => state.order.offerDetails)
  const offerDetails: OfferDetails = {
    promise: offer?.promise,
    promiseDate: offer?.promiseDate,
    contact: offer?.contact,
    visit: offer?.visit,
    dialux: offer?.dialux,
    offerSent: true
  }

  const onClick = (prevLocation: string) => {
    dispatch(selectOffer({ offerDetails }))
    history.push('/tila', { prevLocation })
  }

  return (
    <Container>
      <OfferSentSVG style={{ width: '100%' }} />
      <Heading
        title="Tarjouspyyntö lähetetty"
        body="Olemme sinuun yhteyksissä seuraavan 24 tunnin aikana. Voit tarkastella kohteesi tietoja omista kartoituksista. "
      />
      <BottomBar
        style={{ position: 'absolute', bottom: 30, width: '100%' }}
        maxWidth="sm"
        components={[
          <Button
            color="primary"
            disablePadding
            type="submit"
            fullWidth
            label="SIIRRY OMILLE SIVUILLE"
            onClick={() => onClick('Takaisin')}
          />
        ]}
      />
    </Container>
  )
}

export default OfferSent
