import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'store'
import {
  Container,
  Heading,
  AppBar,
  Button,
  ListItem,
  Dialog,
  BottomBar,
  ErrorPage
} from 'uniqore-components'
import Paper from '@material-ui/core/Paper'
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import format from 'date-fns/format'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import { removeItemFromCart, clearCart } from 'features/order'
import { sessionIsExpired } from 'features/session'
import { CartItem } from 'types/order'
import { useMutation } from '@apollo/client'
import PublicClient from '../api/publicClient'
import { getFullDate, getTime } from './Common/DateFunctions'
import { CREATE_SURVEY_QUERY } from '../graphql/queries'
import CartListItem from './Common/CartListItem'
import defaultError from '../assets/errors/defaultError.svg'

const LocationSummary = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const onBack = () => history.goBack()
  const locationDetails = useSelector((state: AppState) => state.order.locationDetails)
  const offerDetails = useSelector((state: AppState) => state.order.offerDetails)
  const cart = useSelector((state: AppState) => state.order.cart)
  const currentState = useSelector((state: AppState) => state)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'
  const [showItems, setShowItems] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const Alert = (props: AlertProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }
  let lampsQuantity = 0
  let squareMeters = 0
  let resultId = ''

  const nextPage = (prevLocation: string) => {
    history.push('/kartoitus-tallennettu', { prevLocation })
  }

  const [createSurvey, { loading: mutationLoading }] = useMutation(CREATE_SURVEY_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        resultId = mutationResult.ingestForm.result
        localStorage.setItem('resultId', resultId)
        nextPage('Yhteenveto')
      }
    },
    // eslint-disable-next-line consistent-return
    onError(error) {
      if (error) {
        if (!localStorage.getItem('token')) {
          dispatch(sessionIsExpired(true))
        } else {
          const link = {
            url: window.location.pathname,
            text: 'Yritä uudestaan'
          }
          return (
            <div>
              <ErrorPage
                backgroundColor="#fff"
                color="#000"
                image={defaultError}
                link={link}
                text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
              />
            </div>
          )
        }
      }
    }
  })

  cart.products.map(product => {
    lampsQuantity += product.quantity
    product.cartSpace.givenDimensions.map(dimension => {
      const oneZone = dimension.width * dimension.depth
      squareMeters += oneZone
    })
  })

  const toLocaleString = (date: Date) => {
    return new Date(date).toLocaleDateString('fi-FI')
  }

  const editCartItem = (cartItem: CartItem, prevLocation: string) => {
    history.push(`/alustavat-suunnitelmat/${cartItem.id}`, { prevLocation })
  }

  const editLocationDetails = (prevLocation: string) => {
    history.push('/kohteen-tiedot', { prevLocation })
  }

  const editOfferDetails = (prevLocation: string) => {
    history.push('/tarjous-tiedot', { prevLocation })
  }

  const confirmDialog = (prevLocation: string) => {
    dispatch(clearCart())
    setShowDialog(false)
    history.push('/tilan-muoto', { prevLocation })
  }

  const removeCartItem = (cartItem: CartItem) => {
    if (cart.products.length <= 1) {
      setShowDialog(true)
    } else dispatch(removeItemFromCart({ cartItem }))
  }

  const submitHandler = () => {
    const date = new Date()
    localStorage.setItem('createdTime', `${getFullDate(date)} klo ${getTime(date)}`)
    createSurvey({
      variables: {
        id: process.env.REACT_APP_CREATE_SURVEY_FORM_ID,
        form: {
          ...currentState,
          offerDetails: {
            ...offerDetails,
            promiseDate:
              offerDetails?.promiseDate && format(offerDetails.promiseDate, 'yyyy-MM-dd'),
            promiseDateLocal:
              offerDetails?.promiseDate && format(offerDetails.promiseDate, 'dd.MM.yyyy')
          },
          locationDetails: {
            ...locationDetails,
            installationDate:
              locationDetails?.installationDate &&
              format(locationDetails?.installationDate, 'yyyy-MM-dd'),
            installationDateLocal:
              locationDetails?.installationDate &&
              format(locationDetails?.installationDate, 'dd.MM.yyyy')
          },
          currentUser: {
            firstName: localStorage.getItem('firstName'),
            lastName: localStorage.getItem('lastName'),
            phone: localStorage.getItem('phone'),
            city: localStorage.getItem('city'),
            address: localStorage.getItem('address'),
            zip: localStorage.getItem('zip'),
            company: localStorage.getItem('companyName')
          },
          email: localStorage.getItem('email'),
          dateText: `${getFullDate(date)} klo ${getTime(date)}`,
          status: 'Tallennettu'
        }
      }
    })
  }

  return (
    <div>
      <AppBar
        text={previousLocation}
        border
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => (previousLocation === 'Etusivulle' ? history.push('/') : onBack())
        }}
      />
      <Container>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowSnackbar(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert severity="error">
            Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.
          </Alert>
        </Snackbar>
        <Dialog
          title="Varmistus"
          subtitle="Poistetaanko tarjouspyyntö ja palataan etusivulle?"
          fullWidth
          open={showDialog}
          actionComponents={[
            <Button
              label="Peruuta"
              variant="text"
              color="primary"
              disablePadding
              onClick={() => {
                setShowDialog(false)
              }}
            />,
            <Button
              label="Kyllä"
              color="primary"
              disablePadding
              onClick={() => confirmDialog('Yhteenveto')}
            />
          ]}
          onClose={() => setShowDialog(false)}
        />
        <Heading title="Yhteenveto" body="Alla näet yhteenvedon tarjouspyynnöstä" />
        <ListItem
          text="Valaistussuunnitelma"
          subtitles={[
            `${cart.products.length} tilaa, ${lampsQuantity} valaisinta`,
            `Yhteensä ${squareMeters} m2`
          ]}
          metaIcon={showItems ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
          onClick={() => setShowItems(!showItems)}
        />
        {showItems && (
          <div>
            {cart.products.map(cartItem => {
              return (
                <Fade in={showItems}>
                  <Paper style={{ marginBottom: '10px' }} key={Math.random()}>
                    <CartListItem
                      cartItem={cartItem}
                      metaIcon=""
                      actions={[
                        {
                          label: 'Muokkaa',
                          icon: 'Edit',
                          onClick: () => editCartItem(cartItem, 'Yhteenveto')
                        },
                        {
                          label: 'Poista',
                          icon: 'RemoveCircle',
                          onClick: () => {
                            removeCartItem(cartItem)
                          }
                        }
                      ]}
                      onClick={() => {}}
                    />
                  </Paper>
                </Fade>
              )
            })}
          </div>
        )}
        <div style={{ marginBottom: 120 }}>
          <ListItem
            text="Kohteen tiedot"
            subtitles={[
              locationDetails ? locationDetails.name : '',
              `Asennuksen ajankohta: ${
                locationDetails ? toLocaleString(locationDetails.installationDate) : ''
              }`
            ]}
            metaIcon="Edit"
            onClick={() => editLocationDetails('Yhteenveto')}
          />
          {(offerDetails?.promise ||
            offerDetails?.contact ||
            offerDetails?.visit ||
            offerDetails?.dialux) && (
            <ListItem
              text="Lisäpalvelut"
              subtitles={[
                offerDetails?.promise
                  ? `Tarjous jätettävä ${
                      offerDetails?.promiseDate ? toLocaleString(offerDetails.promiseDate) : ''
                    }`
                  : '',
                offerDetails?.contact ? 'Haluan yhteydenoton' : '',
                offerDetails?.visit ? 'Haluan kohdekäynnin' : '',
                offerDetails?.dialux ? 'Haluan Dialux-suunnitelman' : ''
              ]}
              metaIcon="Edit"
              onClick={() => editOfferDetails('Yhteenveto')}
            />
          )}
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              disabled={mutationLoading}
              color="primary"
              disablePadding
              type="submit"
              fullWidth
              label={
                mutationLoading ? (
                  <CircularProgress size="24px" color="inherit" />
                ) : (
                  'TALLENNA KOHDE'
                )
              }
              onClick={() => submitHandler()}
            />
          ]}
        />
      </Container>
    </div>
  )
}

export default LocationSummary
