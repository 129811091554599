import Client from 'api/client'
import { CartItem, CartSpace } from 'types/order'
import { GET_PRODUCTS_QUERY } from 'graphql/queries'
import { ProductRecord, ProductsDataViewData } from 'graphql/types'

const getProducts = async (): Promise<ProductRecord[]> => {
  const { data, error } = await Client.query<ProductsDataViewData>({
    query: GET_PRODUCTS_QUERY,
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_ID
    },
    context: {
      headers: {
        Authorization: `CustomerToken ${localStorage.getItem('token')}`
      }
    }
  })
  if (error) return []

  return data?.fetchDataview.data.records || []
}

const getRoomIndex = (depth: number, height: number, width: number): number => {
  if (depth <= 0 || height <= 0 || width <= 0) return 0

  const netHeight = height - 0.8
  return (depth * width) / (netHeight * (depth + width))
}

/**
 * There is no exact formula for the UF but it roughly translates to equation
 * −0.0234578x2 + 0.210773x + 0.180353.
 * Equation taken from https://www.dcode.fr/function-equation-finder using the given known values.
 * Beyond the known values (< 0.75 || > 5) we use the last known value.
 * @param roomIndex
 */
const getUtilizationFactorForLinearProducts = (roomIndex: number): number => {
  if (roomIndex <= 0.75) return 0.3
  if (roomIndex >= 5) return 0.66

  return -0.0234578 * roomIndex ** 2 + 0.210773 * roomIndex + 0.180353
}

const getUtilizationFactorForOfficeProducts = (roomIndex: number): number => {
  if (roomIndex <= 0.75) return 0.36
  if (roomIndex >= 5) return 0.792
  return -0.0281493 * roomIndex ** 2 + 0.252928 * roomIndex + 0.216424
}

/**
 * Calculate the required quantity of this product to fulfill the specs of the given zone
 * @param spaceLux The lx count requested for the zone
 * @param width Zone dimensions
 * @param depth  Zone dimensions
 * @param productLumens The lumen rating of the product
 * @param uf Factor calculated previously
 * @returns
 */
const getProductCount = (
  spaceLux: number,
  width: number,
  depth: number,
  productLumens: number,
  uf: number,
  railsAmount: number
): number => {
  const result = (spaceLux * width * depth * 0.8) / (productLumens * uf)
  let productAmount = 2 * Math.ceil(result / 2)
  if (productAmount % railsAmount === 0) {
    return productAmount
  }
  while (productAmount % railsAmount !== 0) {
    productAmount += 1
  }

  return productAmount
}

/**
 * Combines the suggestions by product so that there are no duplicate products in the results,
 * and instead their quantities are summed together.
 * @param suggestions
 */
const stackSuggestionsByProduct = (suggestions: CartItem[]) => {
  const results: { [key: string]: CartItem } = {}
  suggestions.map(suggestion => {
    const { quantity, product } = suggestion
    if (!(product.id in results)) {
      results[product.id] = suggestion
    } else {
      results[product.id].quantity += quantity
    }
  })
  return Object.values(results)
}

/**
 * Fetches the available products and calculates the required quantity of each product that is most
 * applicable to the given space.
 * @param cartSpace
 */
export const getProductSuggestions = async (cartSpace: CartSpace): Promise<CartItem[]> => {
  const { givenDimensions, lightType, type, installationDetails } = cartSpace
  // eslint-disable-next-line no-console
  const railsAmount = installationDetails?.railsAmount
  if (!lightType) return []

  const products = await getProducts()

  const chosenInstallationType = installationDetails?.installationType
  const chosenType = type?.installationTypes.filter(
    installationType => installationType.type === chosenInstallationType
  )
  const chosenTypeProducts = chosenType ? chosenType[0].products : []

  const suggestions: CartItem[] = []
  givenDimensions.map(givenDimension => {
    const { depth, height, width } = givenDimension
    const roomIndex = getRoomIndex(depth, height, width)
    products.map(product => {
      const isOfficeProduct = product.fields.SKU.includes('Office')
      const uf = isOfficeProduct
        ? getUtilizationFactorForOfficeProducts(roomIndex)
        : getUtilizationFactorForLinearProducts(roomIndex)
      chosenTypeProducts.forEach(productName => {
        if (product.fields.SKU === productName && railsAmount) {
          const suggestedQty = getProductCount(
            lightType.lux,
            width,
            depth,
            product.fields['Valovirta (lm)'],
            uf,
            railsAmount
          )
          suggestions.push({
            cartSpace,
            quantity: suggestedQty,
            product
          })
        }
      })
    })
  })

  return stackSuggestionsByProduct(suggestions)
}
