import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { sessionIsExpired } from 'features/session'
import { CircularProgress, IconButton, Snackbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Login, Container, TextField, Button } from 'uniqore-components'
import { AppState } from 'store'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PublicClient from 'api/publicClient'
import { useMutation } from '@apollo/client'
import { LOGIN_QUERY } from '../../graphql/queries'
import { passwordResetDone } from '../../features/password-reset'

const useStyles = makeStyles(() => ({
  form: {
    marginTop: '40%'
  }
}))

const SignIn = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { passwordReset } = useSelector((state: AppState) => state.passwordReset)

  const [login, { loading: mutationLoading }] = useMutation(LOGIN_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.loginCustomerPassword.error) {
        setErrorMessage(mutationResult.loginCustomerPassword.error)
      } else if (mutationResult.loginCustomerPassword.token) {
        localStorage.setItem('token', mutationResult.loginCustomerPassword.token)
        localStorage.setItem('userId', mutationResult.loginCustomerPassword.customerId)
        dispatch(sessionIsExpired(false))
        history.push('/')
      }
    }
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    localStorage.setItem('email', email)
    login({
      variables: {
        email,
        password,
        auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
      }
    })
  }

  const onForgotPassword = () => {
    history.push('/palauta-salasana')
  }

  const handleClose = () => {
    dispatch(passwordResetDone(false))
  }

  return (
    <Container maxWidth="sm">
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={passwordReset}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Hienoa! Salasana on vaihdettu."
        color="secondary"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <form className={classes.form} onSubmit={e => handleSubmit(e)}>
        <Login
          headline="Kirjaudu sisään"
          subtitle=""
          contentComponents={[
            <TextField
              autoFocus
              fullWidth
              placeholder="Sähköposti"
              prefix={{ icon: 'MailOutline' }}
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              errorText={errorMessage}
            />,
            <TextField
              fullWidth
              placeholder="Salasana"
              type={passwordVisible ? 'text' : 'password'}
              prefix={{ icon: 'LockOutlined' }}
              suffix={{
                icon: passwordVisible ? 'Visibility' : 'VisibilityOff',
                onClick: () => setPasswordVisible(!passwordVisible)
              }}
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              errorText={errorMessage}
            />
          ]}
          actionComponents={[
            <Button
              type="submit"
              color="primary"
              fullWidth
              disabled={mutationLoading}
              label={
                mutationLoading ? (
                  <CircularProgress size="24px" color="inherit" />
                ) : (
                  'KIRJAUDU SISÄÄN'
                )
              }
            />,
            <Button
              label="Salasana unohtunut?"
              variant="text"
              fullWidth
              color="primary"
              onClick={onForgotPassword}
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default SignIn
