import React, { useState } from 'react'
import {
  Container,
  Heading,
  AppBar,
  TextField,
  Button,
  DatePicker,
  BottomBar
} from 'uniqore-components'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { LocationDetails } from 'types/order'
import { useLocation } from 'react-router-dom'
import { selectLocation } from 'features/order'
import { useHistory } from 'react-router'

const LocationDetailsPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const onBack = () => history.goBack()
  const currentLocation = useSelector((state: AppState) => state.order.locationDetails)
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const [name, setName] = useState<string>(currentLocation?.name ? currentLocation.name : '')
  const [address, setAddress] = useState<string>(
    currentLocation?.address ? currentLocation.address : ''
  )
  const [postcode, setPostcode] = useState<string>(
    currentLocation?.postCode ? currentLocation.postCode.toString() : ''
  )
  const [postAddress, setPostAddress] = useState<string>(
    currentLocation?.postAddress ? currentLocation.postAddress : ''
  )
  const [installationDate, setDate] = useState<Date>(
    currentLocation?.installationDate ?? new Date()
  )
  const [comment, setComment] = useState<string>(
    currentLocation?.additional ? currentLocation.additional : ''
  )
  const [errorText, setErrorText] = useState<string>('')
  const [invalidField, setInvalidField] = useState<string>('')

  const changeDate = (date: Date) => {
    setDate(date)
  }

  const locationDetails: LocationDetails = {
    name,
    address,
    postCode: Number(postcode),
    postAddress,
    installationDate,
    additional: comment
  }

  const submitHandler = (e: React.FormEvent, prevLocation: string) => {
    e.preventDefault()
    dispatch(selectLocation({ locationDetails }))
    if (name && (locationDetails.postCode || locationDetails.postCode === 0)) {
      history.push('/tarjous-tiedot', { prevLocation })
      setErrorText('')
      setInvalidField('')
      // eslint-disable-next-line no-restricted-globals
    } else if (!name && isNaN(locationDetails.postCode)) {
      setErrorText('Tämä kenttä on pakollinen')
      setInvalidField('Virheellinen kenttä')
      // eslint-disable-next-line no-restricted-globals
    } else if (isNaN(locationDetails.postCode)) {
      setInvalidField('Virheellinen kenttä')
      setErrorText('')
    } else if (!name) {
      setErrorText('Tämä kenttä on pakollinen')
      setInvalidField('')
    }
  }
  return (
    <div>
      <AppBar
        text={previousLocation}
        maxWidth="sm"
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading
          title="Kohteen tiedot"
          body="Lisää kohteen tarkemmat tiedot. Kohteen nimi on pakollinen kohta."
        />
        <form style={{ marginBottom: 120 }}>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus
            fullWidth
            placeholder="Kohteen nimi"
            label="Kohteen nimi"
            required
            errorText={errorText}
          />
          <TextField
            value={address}
            onChange={e => setAddress(e.target.value)}
            fullWidth
            placeholder="Katuosoite"
            label="Katuosoite"
          />
          <TextField
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
            fullWidth
            placeholder="Postinumero"
            errorText={invalidField}
            label="Postinumero"
          />
          <TextField
            value={postAddress}
            onChange={e => setPostAddress(e.target.value)}
            fullWidth
            placeholder="Postitoimipaikka"
            label="Postitoimipaikka"
          />
          <DatePicker
            date={installationDate}
            label="Asennuksen ajankohta"
            variant="inline"
            onChange={changeDate}
            disablePast
          />
          <TextField
            value={comment}
            onChange={e => setComment(e.target.value)}
            fullWidth
            placeholder="Lisätietoja kohteesta"
            label="Lisätietoja kohteesta"
            rows={3}
          />
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                color="primary"
                disablePadding
                type="submit"
                fullWidth
                label="ETEENPÄIN"
                onClick={(e: React.FormEvent) => submitHandler(e, 'Kohteen tiedot')}
              />
            ]}
          />
        </form>
      </Container>
    </div>
  )
}

export default LocationDetailsPage
