import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { AppState } from 'store'
import { AppBar, Button, Container, Heading, TextField, BottomBar } from 'uniqore-components'
import { selectInstallationDetails } from 'features/order'

const InstallationType = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [comment, setComment] = useState('')
  const activeType = useSelector((state: AppState) => state.order.currentDraft?.installationDetails)
  const installationTypes = useSelector(
    (state: AppState) => state.order.currentDraft?.type?.installationTypes
  )
  const { state } = useLocation<{ prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'

  const onBack = () => history.goBack()

  const selectInstallation = (installationDetails: {
    installationType: string
    installationComment?: string | undefined
    railsAmount?: number | undefined
  }) => {
    dispatch(selectInstallationDetails({ installationDetails }))
  }

  const onButtonClick = (prevLocation: string) => {
    // TODO. Product suggestion calculations
    selectInstallation({
      installationType: activeType?.installationType ?? '',
      installationComment: comment,
      railsAmount: activeType?.railsAmount ?? 0
    })
    if (activeType) history.push('/ehdotukset', { prevLocation })
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        <Heading title="Asennustapa" subtitle="Valitse seuraavista jokin asennustapa." />
        <div>
          {installationTypes?.map(type => (
            <Button
              label={type.type}
              key={Math.random()}
              checked={activeType?.installationType === type.type}
              role="radio"
              onClick={() =>
                selectInstallation({
                  installationType: type.type,
                  installationComment: activeType?.installationComment ?? '',
                  railsAmount: activeType?.railsAmount ?? 0
                })
              }
            />
          ))}
        </div>
        <Heading
          title="Valaisinlinjojen määrä"
          subtitle="Esim. kiskojen määrä."
          style={{ marginTop: 16 }}
        />
        <TextField
          fullWidth
          value={String(activeType?.railsAmount) ?? 0}
          type="number"
          label="Syötä määrä"
          onChange={e =>
            selectInstallation({
              installationType: activeType?.installationType ?? '',
              installationComment: activeType?.installationComment ?? '',
              railsAmount: Number(e.target.value)
            })
          }
          suffix={{ text: 'kpl' }}
        />
        <TextField
          value={comment}
          onChange={e => {
            setComment(e.target.value)
          }}
          fullWidth
          rows={3}
          placeholder="Esim. kiinnitystapa, koko, onko määritelty kiskojen määrää vielä.."
          style={{ marginBottom: 40 }}
        />
        <div style={{ width: 200, height: 25 }} />
        <BottomBar
          style={{
            position: 'absolute',
            bottom: 30,
            width: '100%'
          }}
          maxWidth="sm"
          components={[
            <Button
              color="primary"
              disablePadding
              type="submit"
              fullWidth
              label="ETEENPÄIN"
              onClick={() => onButtonClick('Asennustapa')}
              disabled={!activeType?.installationType || !activeType.railsAmount}
            />
          ]}
        />
      </Container>
    </div>
  )
}

export default InstallationType
