import React from 'react'
import { addItemToCart, setCurrentDraft } from 'features/order'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { CartItem } from 'types/order'
import { AppBar, Button, Container, Heading, ListItem, BottomBar } from 'uniqore-components'
import { Center } from './Common/Utilities'

const ProductImage = styled.img`
  height: 30vh;
  width: auto;
`

const Product = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = useLocation<{ cartItem: CartItem; prevLocation: string }>()
  const previousLocation = state?.prevLocation || 'Takaisin'
  const product = state?.cartItem?.product

  const onBack = () => {
    // In case we are returning from cart, we re-activate the draft
    dispatch(setCurrentDraft({ cartSpace: state?.cartItem?.cartSpace }))
    history.goBack()
  }

  const onClick = (prevLocation: string) => {
    if (state.cartItem) {
      const { cartItem } = state
      dispatch(addItemToCart({ cartItem: { ...cartItem, id: cartItem.cartSpace.id } }))
      history.push('/alustavat-suunnitelmat', { previousItemId: state.cartItem.id, prevLocation })
    }
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        text={previousLocation}
        border
        mainAction={{ icon: 'ArrowBack', onClick: onBack }}
      />
      <Container>
        {product && (
          <div style={{ marginBottom: 120 }}>
            <Center>
              <ProductImage src={product.fields.Kuva[0].url} alt="Tuotekuva" />
            </Center>
            <Heading title={`SteraLux ${product.fields.SKU}`} align="left" />
            <ListItem
              subtitles={[
                `Teho: ${product.fields['Teho (W)']} W`,
                `Valovirta: ${product.fields['Valovirta (lm)']} lumen`,
                `Pituus: ${product.fields['Pituus (mm)']} mm`,
                `Leveys: ${product.fields['Leveys (mm)']} mm`
              ]}
            />
            <BottomBar
              style={{ position: 'absolute', bottom: 30, width: '100%' }}
              maxWidth="sm"
              components={[
                <Button
                  color="primary"
                  disablePadding
                  type="submit"
                  fullWidth
                  label="VALITSE JA LISÄÄ"
                  onClick={() => onClick('Tuotteen tiedot')}
                />
              ]}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Product
