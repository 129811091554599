import React, { useState, useEffect, BaseSyntheticEvent } from 'react'
import {
  Container,
  StatusHero,
  AppBar,
  Button,
  FeedTitle,
  FeedCard,
  SpeedDial,
  ErrorPage
} from 'uniqore-components'
import { makeStyles } from '@material-ui/core/styles'
import logo from 'assets/svg/logo.svg'
import { CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { setCurrentDraft, clearCart, clearLocationDetails, clearOfferDetails } from 'features/order'
import { useDispatch } from 'react-redux'
import { sessionIsExpired } from 'features/session'
import { useHistory } from 'react-router'
import { useQuery } from '@apollo/client'
import { logOut } from 'components/Auth/LogOut'
import { FeedCardType } from '../types/survey'
import { GET_ENGAGEMENTS_QUERY, GET_EVENTS_QUERY } from '../graphql/queries'
import PublicClient from '../api/publicClient'
import defaultError from '../assets/errors/defaultError.svg'

const useStyles = makeStyles(() => ({
  speedDial: {
    position: 'fixed',
    right: 10,
    bottom: 10
  },
  feedcardsLoader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  feedCard: {
    '& [class*="MuiButtonBase-root"]': {
      cursor: 'default',
      '& > span': {
        all: 'unset',
        '& > span': {
          all: 'unset'
        }
      }
    }
  }
}))

const Status = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    data: engagementsData,
    loading,
    error
  } = useQuery(GET_ENGAGEMENTS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let records = []
  if (engagementsData) {
    records = engagementsData?.serviceEngagements
  }

  const {
    data: feedcardsData,
    loading: feedcardsLoading,
    error: feedcardsError
  } = useQuery(GET_EVENTS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: process.env.REACT_APP_GET_EVENTS_ID
    }
  })

  const [feedcards, setFeedcards] = useState<FeedCardType[]>([])
  useEffect(() => {
    if (feedcardsData) {
      setFeedcards(feedcardsData?.fetchDataview?.data)
    }
  }, [feedcardsData])
  const lastFeedcards = feedcards?.slice().reverse().slice(0, 10)

  const showSurveys = (prevLocation: string) => {
    history.push('/omat-kartoitukset', { prevLocation })
  }

  const contactUs = (prevLocation: string) => {
    history.push('/ota-yhteyttä', { prevLocation })
  }

  const handleAppBarClick = (e: BaseSyntheticEvent) => {
    setOpenMenu(true)
    setAnchorEl(e.currentTarget)
  }

  if (error || feedcardsError) {
    if (!localStorage.getItem('token')) {
      dispatch(sessionIsExpired(true))
    } else {
      const link = {
        url: window.location.pathname,
        text: 'Yritä uudestaan'
      }
      return (
        <div>
          <ErrorPage
            backgroundColor="#fff"
            color="#000"
            image={defaultError}
            link={link}
            text="Tapahtui virhe, yritä uudestaan tai ota yhteyttä asiakaspalveluun."
          />
        </div>
      )
    }
  }
  return loading || feedcardsLoading ? (
    <div className={classes.feedcardsLoader}>
      <CircularProgress size="36px" color="inherit" style={{ marginTop: 10 }} />
    </div>
  ) : (
    <>
      <AppBar
        backgroundColor="#394247"
        border
        logo={logo}
        logoSize={120}
        maxWidth="sm"
        color="white"
        actions={[{ icon: 'MoreVert', onClick: e => handleAppBarClick(e) }]}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        style={{ marginTop: 20 }}
      >
        <MenuItem
          onClick={() => {
            logOut()
          }}
        >
          Kirjaudu ulos
        </MenuItem>
      </Menu>
      <StatusHero
        backgroundColor="#394247"
        color="white"
        headline={`Hei, ${localStorage.getItem('firstName')}`}
        subtitle={`Tallennetut omat kartoitukset (${records.length})`}
        actionComponent={
          <Button
            icon="Add"
            color="primary"
            label="Luo uusi kartoitus"
            onClick={() => {
              dispatch(setCurrentDraft({ cartSpace: undefined }))
              dispatch(clearCart())
              dispatch(clearLocationDetails())
              dispatch(clearOfferDetails())
              history.push('/tilan-muoto')
            }}
          />
        }
      />
      <Button
        label="Omat kartoitukset"
        icon="Done"
        variant="text"
        color="primary"
        style={{ textAlign: 'center' }}
        underline
        onClick={() => showSurveys('Etusivulle')}
      />
      <Container>
        <FeedTitle text="Viimeaikainen toiminta" />
        {lastFeedcards.map((feedcard: FeedCardType, i: number) => {
          return (
            <FeedCard
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={classes.feedCard}
              date={feedcard?.createSurvey?.date || feedcard?.updateSurvey?.date}
              subtitle={feedcard?.createSurvey?.subtitle || feedcard?.updateSurvey?.subtitle}
              user={feedcard?.createSurvey?.user || feedcard?.updateSurvey?.user}
              text={feedcard?.createSurvey?.text || feedcard?.updateSurvey?.text}
              avatarBackgroundColor={
                feedcard?.createSurvey?.avatarBackgroundColor ||
                feedcard?.updateSurvey?.avatarBackgroundColor
              }
              avatarColor={
                feedcard?.createSurvey?.avatarColor || feedcard?.updateSurvey?.avatarColor
              }
            />
          )
        })}
      </Container>
      <SpeedDial
        className={classes.speedDial}
        actions={[
          {
            label: 'Ota yhteyttä',
            icon: 'Comment',
            onClick: () => contactUs('Etusivulle')
          },
          {
            label: 'Omat kartoitukset',
            icon: 'List',
            onClick: () => showSurveys('Etusivulle')
          },
          {
            label: 'Luo uusi kartoitus',
            icon: 'Add',
            onClick: () => {
              dispatch(setCurrentDraft({ cartSpace: undefined }))
              dispatch(clearCart())
              dispatch(clearLocationDetails())
              dispatch(clearOfferDetails())
              history.push('/tilan-muoto')
            }
          }
        ]}
      />
    </>
  )
}

export default Status
