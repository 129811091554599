import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, InputAdornment, CircularProgress } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useMutation } from '@apollo/client'
import { sessionIsExpired } from 'features/session'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { Button } from 'uniqore-components'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom'
import PublicClient from '../../api/publicClient'
import { LOGIN_QUERY } from '../../graphql/queries'

const useStyles = makeStyles(theme => ({
  title: {
    padding: '16px 16px 0px 24px',
    color: theme.palette.text.primary
  },
  dialogActions: {
    padding: '12px 6px'
  },
  spinnerOnButton: {
    position: 'absolute',
    width: '30px',
    zIndex: 1,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  inputWrapper: {
    marginTop: 32
  }
}))

const LoginDialog = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleClick = () => {
    setShowPassword(prev => !prev)
  }

  const [login, { loading: mutationLoading }] = useMutation(LOGIN_QUERY, {
    client: PublicClient,
    onCompleted(mutationResult) {
      if (mutationResult.loginCustomerPassword.error) {
        setError(mutationResult.loginCustomerPassword.error)
      } else if (mutationResult.loginCustomerPassword.token) {
        localStorage.setItem('token', mutationResult.loginCustomerPassword.token)
        localStorage.setItem('userId', mutationResult.loginCustomerPassword.customerId)
        dispatch(sessionIsExpired(false))
        history.push(window.location.pathname)
      }
    }
  })

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    localStorage.setItem('email', email)
    login({
      variables: {
        email,
        password,
        auth: process.env.REACT_APP_CUSTOMER_AUTH_ID
      }
    })
  }

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <div>
        <Typography variant="h5" className={classes.title}>
          Istuntosi on vanhentunut. Kirjaudu uudelleen.
        </Typography>
        <DialogContent>
          <div className={classes.inputWrapper}>
            <TextField
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              placeholder="Email"
              fullWidth
              variant="outlined"
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              placeholder="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={!!error}
              helperText={error}
            />
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            label="Salasana unohtunut?"
            variant="text"
            color="secondary"
            onClick={() => history.push('/palauta-salasana')}
          />
          <Button
            label={
              mutationLoading ? <CircularProgress size="24px" color="inherit" /> : 'KIRJAUDU SISÄÄN'
            }
            variant="contained"
            color="primary"
            onClick={submit}
          />
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default LoginDialog
