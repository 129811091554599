import React, { useState, BaseSyntheticEvent } from 'react'
import onboarding from 'assets/svg/onboarding.svg'
import logo from 'assets/svg/logo.svg'
import { Button, Onboarding, Dialog, Container, Spinner, AppBar } from 'uniqore-components'
import { Menu, MenuItem } from '@material-ui/core'
import { acceptedCookies } from 'features/session'
import { setCurrentDraft, clearCart, clearLocationDetails, clearOfferDetails } from 'features/order'
import { useHistory } from 'react-router'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { logOut } from 'components/Auth/LogOut'
import PublicClient from '../api/publicClient'
import { GET_CUSTOMER_INFO, GET_ENGAGEMENTS_QUERY } from '../graphql/queries'

const FirstTimeOnboarding = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const cookiesConfirmed = useSelector((state: AppState) => state.session.acceptedCookies)

  const onClick = (prevLocation: string) => {
    dispatch(setCurrentDraft({ cartSpace: undefined }))
    dispatch(clearCart())
    dispatch(clearLocationDetails())
    dispatch(clearOfferDetails())
    history.push('/tilan-muoto', { prevLocation })
  }

  const goToStatusPage = () => {
    history.push('/tila')
  }

  const handleAppBarClick = (e: BaseSyntheticEvent) => {
    setOpenMenu(true)
    setAnchorEl(e.currentTarget)
  }

  const { data, loading, error } = useQuery(GET_CUSTOMER_INFO, {
    client: PublicClient
  })

  if (data && !loading) {
    localStorage.setItem('firstName', data.thisCustomer.firstName)
    localStorage.setItem('lastName', data.thisCustomer.lastName)
    localStorage.setItem('phone', data.thisCustomer.phone)
    localStorage.setItem('companyName', data.thisCustomer.companyName)
    localStorage.setItem('address', data.thisCustomer.address)
    localStorage.setItem('zip', data.thisCustomer.zip)
    localStorage.setItem('city', data.thisCustomer.city)
  }

  const { data: engagementsData, loading: surveysLoading } = useQuery(GET_ENGAGEMENTS_QUERY, {
    client: PublicClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  let records = []
  if (engagementsData) {
    records = engagementsData.serviceEngagements
  }

  if (loading || surveysLoading) {
    return (
      <Container>
        <div
          style={{
            width: 60,
            margin: '25px auto'
          }}
        >
          <Spinner />
        </div>
      </Container>
    )
  }

  if (error) {
    history.push('/kirjaudu')
  }

  return (
    <>
      <Onboarding
        appbar={
          <AppBar
            backgroundColor="#394247"
            border
            logo={logo}
            logoSize={120}
            maxWidth="xl"
            color="white"
            actions={[{ icon: 'MoreVert', onClick: e => handleAppBarClick(e) }]}
          />
        }
        screens={[
          {
            title: 'Tervetuloa',
            subtitle:
              'Tervetuloa SteraLuxin asentajapalveluun. Palvelun avulla voit määritellä helposti ja nopeasti tilan valaistustarpeen sekä saat ehdotuksen tilaan sopivista tuotteista.',
            image: onboarding
          }
        ]}
        actionComponents={
          records.length === 0
            ? [
                <Button
                  label="ALOITA KARTOITUS"
                  color="primary"
                  onClick={() => onClick('Takaisin etusivulle')}
                />
              ]
            : [
                <Button
                  label="ALOITA KARTOITUS"
                  color="primary"
                  onClick={() => onClick('Takaisin etusivulle')}
                />,
                <Button
                  label="SIIRRY ETUSIVULLE"
                  color="primary"
                  onClick={() => goToStatusPage()}
                />
              ]
        }
        showIndicators={false}
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        style={{ marginTop: 30 }}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <MenuItem
          onClick={() => {
            logOut()
          }}
        >
          Kirjaudu ulos
        </MenuItem>
      </Menu>
      <Dialog
        open={!cookiesConfirmed}
        maxWidth="xs"
        onClose={() => dispatch(acceptedCookies(true))}
        title="Tervetuloa!"
        fullWidth
        subtitle="Kiitos, että olet kiinnostunut Steraluxistä. Jatkaessasi tilaustasi
        hyväksyt käyttämämme evästeet."
        actionComponents={[
          <Button
            label="Asia selvä"
            color="primary"
            disablePadding
            onClick={() => dispatch(acceptedCookies(true))}
          />
        ]}
      />
    </>
  )
}

export default FirstTimeOnboarding
