import { gql } from '@apollo/client'

export const GET_PRODUCTS_QUERY = gql`
  query GetProducts($id: ID!) {
    fetchDataview(id: $id)
  }
`
export const LOGIN_QUERY = gql`
  mutation login($auth: ID!, $email: String!, $password: String!) {
    loginCustomerPassword(auth: $auth, email: $email, password: $password) {
      token
      error
      customerId
    }
  }
`

export const GET_CUSTOMER_QUERY = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      firstName
    }
  }
`

export const RESET_EMAIL_QUERY = gql`
  mutation reset($auth: ID!, $email: String) {
    resetCustomerPassword(auth: $auth, email: $email) {
      ok
      error
    }
  }
`

export const RESET_PASSWORD_QUERY = gql`
  mutation changePassword(
    $auth: ID!
    $uid: String
    $code: String
    $password: String
    $passwordRepeat: String
  ) {
    resetCustomerPassword(
      auth: $auth
      uid: $uid
      code: $code
      password: $password
      passwordRepeat: $passwordRepeat
    ) {
      ok
      error
    }
  }
`
export const GET_CUSTOMER_INFO = gql`
  query thisCustomer {
    thisCustomer {
      firstName
      lastName
      phone
      companyName
      address
      zip
      city
    }
  }
`

export const CREATE_SURVEY_QUERY = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`
export const UPDATE_SURVEY_QUERY = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const GET_UNIQORE_ID_QUERY = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      id
      result
      state
    }
  }
`

export const GET_EVENTS_QUERY = gql`
  query fetchDataview($id: ID!) {
    fetchDataview(id: $id)
  }
`

export const GET_ENGAGEMENTS_QUERY = gql`
  query serviceEngagements {
    serviceEngagements {
      id
      metadata {
        key
        value
      }
    }
  }
`
