import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  passwordReset: false
}

const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    passwordResetDone(state, { payload }: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.passwordReset = payload
    }
  }
})

export const { passwordResetDone } = passwordResetSlice.actions
export default passwordResetSlice.reducer
