import React from 'react'
import { CartItem, CartSpace } from 'types/order'
import { ListItem } from 'uniqore-components'
import { ListAction } from 'uniqore-components/dist/components/ListItem/ListItem'

const getCartSpaceTitle = (cartSpace: CartSpace) => {
  if (!cartSpace.lightType || !cartSpace.type) return ''
  return `${cartSpace.type.label} / ${cartSpace.lightType.label} (${cartSpace.lightType.lux} lx)`
}

const getCartItemSubtitles = (cartItem: CartItem) => {
  const rows: string[] = []
  cartItem.cartSpace.givenDimensions.map(d => {
    rows.push(`${d.width} m (X) x ${d.depth} m (Z), ${d.height} m (Y)`)
  })
  rows.push(`${cartItem.product.fields.SKU} (${cartItem.quantity} kpl)`)
  rows.push(`Asennustapa: ${cartItem.cartSpace.installationDetails?.installationType}`)
  rows.push(`Valaisinlinjojen määrä: ${cartItem.cartSpace.installationDetails?.railsAmount}`)
  return rows
}

interface Props {
  cartItem: CartItem
  onClick: () => void
  actions: ListAction[]
  metaIcon: string
}

const CartListItem = ({ cartItem, onClick, actions, metaIcon }: Props) => {
  return (
    <ListItem
      text={getCartSpaceTitle(cartItem.cartSpace)}
      image={cartItem.cartSpace.space.image}
      metaIcon={metaIcon}
      subtitles={getCartItemSubtitles(cartItem)}
      hightlightMeta
      onClick={onClick}
      actions={actions}
    />
  )
}

export default CartListItem
